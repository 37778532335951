var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      staticClass: "page-title-container pt-5 pb-11",
      attrs: { tile: "", color: "expandHeader" },
    },
    [
      _c("v-container", { staticClass: "text-center white--text" }, [
        _c("h1", { staticClass: "mb-3" }, [_vm._v(_vm._s(_vm.title))]),
        _c(
          "div",
          { staticClass: "mx-auto", staticStyle: { "max-width": "550px" } },
          [
            _c("h4", [_vm._v(_vm._s(_vm.subtitleLineOne))]),
            _c(
              "h4",
              [
                _vm._v(" " + _vm._s(_vm.subtitleLineTwo) + " "),
                _vm.hasLink
                  ? _c(
                      "router-link",
                      {
                        staticClass: "text-decoration--underline",
                        attrs: { to: { name: _vm.routeObj.routeName } },
                      },
                      [_vm._v(" " + _vm._s(_vm.routeObj.text) + " ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }